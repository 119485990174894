import { render, staticRenderFns } from "./general_report_by_flat_legacy.vue?vue&type=template&id=46e2fd67&scoped=true&"
import script from "./general_report_by_flat_legacy.vue?vue&type=script&lang=js&"
export * from "./general_report_by_flat_legacy.vue?vue&type=script&lang=js&"
import style0 from "./general_report_by_flat_legacy.vue?vue&type=style&index=0&id=46e2fd67&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46e2fd67",
  null
  
)

export default component.exports